<script setup lang="ts">
const props = defineProps<{
  testimonialSectionRef: HTMLElement
}>()

const cardsContainer = ref()

const controlsRef = ref()
const cardsWidth = ref(0)
const cardWidth = ref(700)

const resizeCards = () => {
  const { testimonialSectionRef } = props
  if (!testimonialSectionRef) return
  const cards = testimonialSectionRef.querySelectorAll<HTMLDivElement>('.card')
  if (cards.length > 0) {
    cardWidth.value = cards[0].offsetWidth + 16
  }

  const containerWidth = testimonialSectionRef.offsetWidth

  const windowWidth = window.innerWidth
  const computedStyle = getComputedStyle(testimonialSectionRef)
  const padding = parseInt(computedStyle.paddingLeft.replace('px', ''), 10) + 40

  const remainingSpace = Math.max(windowWidth - containerWidth, 0)
  const marginLeft = Math.floor(remainingSpace / 2)
  const controlsWidth = controlsRef.value.offsetWidth
  const final = windowWidth - (marginLeft + controlsWidth) - padding
  cardsWidth.value = final
}

onMounted(() => {
  window.addEventListener('resize', resizeCards)
  resizeCards()
})

onUnmounted(() => {
  window.removeEventListener('resize', resizeCards)
})

const canMoveLeft = ref(false)
const canMoveRight = ref(true)
const scrollPosition = ref(0)

const updateControls = () => {
  canMoveLeft.value = scrollPosition.value > 0
  canMoveRight.value =
    scrollPosition.value <
    cardsContainer.value.scrollWidth - cardsContainer.value.clientWidth
}

const moveLeft = () => {
  scrollPosition.value -= cardWidth.value
}

const moveRight = () => {
  scrollPosition.value += cardWidth.value
}

watch(scrollPosition, (newValue) => {
  updateControls()
  cardsContainer.value.style.transform = `translateX(-${newValue}px)`
})
</script>

<template>
  <div class="carousel-container">
    <div class="controls-container lg:h-[724px] lg:w-[320px]" ref="controlsRef">
      <h2 class="text-3xl md:text-6xl font-bold w-full">
        Hear From Satisfied Seniors
      </h2>
      <div class="flex gap-4">
        <button
          :disabled="!canMoveLeft"
          @click="moveLeft"
          class="bg-black rounded-lg p-4 disabled:bg-gray-700"
        >
          <Icon i-heroicons-outline:arrow-left class="w-12 h-12 text-white" />
        </button>
        <button
          :disabled="!canMoveRight"
          @click="moveRight"
          class="bg-black rounded-lg p-4 disabled:bg-gray-700"
        >
          <Icon i-heroicons-outline:arrow-right class="w-12 h-12 text-white" />
        </button>
      </div>
    </div>
    <div
      class="cards-container min-h-[300px]"
      :style="{ minWidth: cardsWidth + 'px' }"
    >
      <div class="w-full h-full space-x-4" ref="cardsContainer">
        <slot />
      </div>
    </div>
  </div>
</template>

<style scoped>
.carousel-container {
  @apply flex flex-col gap-8;

  @media screen and (min-width: 1024px) {
    display: grid;
    grid-template-columns: auto 1fr;
  }
}

.controls-container {
  @apply flex md:flex-row flex-col justify-center gap-20 mb-10;
}

.cards-container {
  overflow-x: hidden;
  white-space: nowrap;
  transition: transform 0.3s ease-out;
}

.cards-container > div {
  transition: transform 0.3s ease-out;
}
</style>
