<script setup lang="ts">
import avatar1 from '../../assets/landing-page-pc-barbara.png'
import avatar5 from '../../assets/landing-page-pc-dorothy.jpeg'
import avatar4 from '../../assets/landing-page-pc-james.jpeg'
import avatar3 from '../../assets/landing-page-pc-margaret.png'
import avatar2 from '../../assets/landing-page-pc-robert.jpeg'
import { breakpointsTailwind } from '@vueuse/core'

useHead({
  title: 'PatientConnectxion.com',
})

analytics.page({
  name: 'PatientConnectxion.com',
  category: 'LP',
})

const testimonials = [
  {
    avatar: avatar1,
    testimonial:
      'I love the personalized attention I receive with <strong>Value-Based Care</strong>. I feel like my voice is heard, and my healthcare is tailored to my needs.',
    name: 'Barbara D., 72',
    bgColor: '#FFF9F1',
  },
  {
    avatar: avatar2,
    testimonial:
      '<strong>Value-Based Care</strong> has made a huge difference in my life. The focus on prevention and well-being has helped me maintain an healthy lifestyle.',
    name: 'Robert S., 68',
    bgColor: '#F2E8E9',
  },
  {
    avatar: avatar3,
    testimonial:
      'As a senior, I appreciate the emphasis on coordinated care in the <strong> Value-Based Care</strong> model. It ensures making my care more efficient and effective.',
    name: 'Margaret B., 71',
    bgColor: '#E3F3F9',
  },
  {
    avatar: avatar4,
    testimonial:
      'The <strong>Value-Based Care</strong> approach has been a game-changer for me. With the focus on quality over quantity, I feel like my care is personalized and comprehensive.',
    name: 'James L., 67',
    bgColor: '#E9F6E6',
  },
  {
    avatar: avatar5,
    testimonial:
      'I am grateful for the <strong>Value-Based Care</strong> system. The emphasis on patient engagement and shared decision-making has been truly empowering.',
    name: 'Dorothy P., 76',
    bgColor: '#FDF1F6',
  },
]

const testimonialSectionRef = ref()

const breakpoints = useBreakpoints(breakpointsTailwind)
const isMobile = breakpoints.smaller('md')
</script>

<template>
  <Layout header="pc" footer="pc" :hide-footer="isMobile">
    <header class="flex items-center justify-center flex-col gap-4 h-full hero">
      <div class="container md:space-y-4 space-y-6 py-24">
        <h1 class="text-4xl md:text-6xl font-bold">
          Discover <br />Value-Based <br />Care for Seniors
        </h1>
        <h2 class="text-lg md:text-xl">
          Explore a new approach to healthcare that <br />prioritizes your
          well-being and satisfaction
        </h2>
        <Button
          variant="blue"
          size="sx"
          class="rounded-2xl"
          to="/paap/save-money-stay-healthy"
        >
          Learn More About Value-Based Care!
        </Button>
      </div>
    </header>
    <div class="container justify-end -mt-[210px] mb-8 hidden lg:flex">
      <img src="../../assets/landing-page-pc-square-1.png" />
    </div>
    <div class="container lg:space-y-36 md:space-y-28 space-y-16">
      <section>
        <div class="grid-square mt-4 lg:mt-0 md:gap-0 gap-column-4">
          <img
            src="../../assets/landing-page-pc-square-2.png"
            class="w-32 h-32 md:w-auto md:h-auto md:ml-0 ml-2"
          />
          <div
            class="first-row-text md:text-6xl text-xl md:ml-14 ml-1 md:pr-12"
          >
            What is <br />
            value-based care?
          </div>
          <img
            src="../../assets/landing-page-pc-square-3.png"
            class="square-3 justify-self-end lg:flex hidden md:w-auto md:h-auto"
          />
          <img
            src="../../assets/landing-page-pc-heart.png"
            class="heart w-10 h-10 md:w-auto md:h-auto md:-ml-20 lg:-ml-6 -ml-8"
          />
          <div class="heart" />
          <img
            src="../../assets/landing-page-pc-square-4.png"
            class="w-32 h-32 md:w-auto md:h-auto md:ml-0 ml-2"
          />
          <img
            src="../../assets/landing-page-pc-square-5.png"
            class="w-32 h-32 md:w-auto md:h-auto"
          />
          <div class="second-row-text lg:px-14 px-4 mt-4">
            <strong>Value-Based Care</strong> is a patient-centered approach to
            healthcare that focuses on providing high-quality, personalized care
            for seniors like you. Instead of paying for the number of services
            received, your healthcare providers are rewarded for keeping you
            healthy and achieving better health outcomes.
            <Link to="/articles">READ MORE</Link>
          </div>
        </div>
      </section>

      <section class="md:space-y-10 text-center">
        <h2 class="text-2xl md:text-6xl font-bold"
          >Why Choose Value-Based Care for Seniors?</h2
        >
        <div class="grid-square lg:mt-20 mt-10 gap-y-10">
          <div class="flex items-center flex-col gap-4">
            <img src="../../assets/landing-page-pc-2nd-row-1.png" />
            <h3 class="font-bold text-2xl px-8 mb-4"
              >Enhanced Patient Experience
            </h3>
            <p class="text-base px-8"
              >Enjoy personalized care tailored to your unique needs and
              preferences</p
            >
          </div>
          <div class="flex items-center flex-col gap-4">
            <img src="../../assets/landing-page-pc-2nd-row-2.png" />
            <h3 class="font-bold text-2xl px-8 mb-4">Proactive Healthcare </h3>
            <p class="text-base px-8"
              >Benefit from a focus on prevention and early intervention to
              ensure better health outcomes</p
            >
          </div>
          <div class="flex items-center flex-col gap-4">
            <img src="../../assets/landing-page-pc-2nd-row-3.png" />
            <h3 class="font-bold text-2xl px-8 mb-4">Coordination of Care</h3>
            <p class="text-base px-8">
              Experience seamless care with a team of providers working together
              to support your well-being
            </p>
          </div>
          <div class="flex items-center flex-col gap-4">
            <img src="../../assets/landing-page-pc-2nd-row-4.png" />
            <h3 class="font-bold text-2xl px-8 mb-4">Cost Savings</h3>
            <p class="text-base px-8"
              >Save on healthcare expenses as providers prioritize efficiency
              and high-quality care
            </p>
          </div>
        </div>
      </section>

      <section>
        <div
          class="md:rounded-t-full md:rounded-br-full rounded-t-3xl rounded-br-3xl bg-slate-100 py-12 md:px-20 px-8 flex items-center md:justify-start justify-center gap-8 justify-between flex-col md:flex-row"
        >
          <div
            class="rounded-t-full rounded-bl-full bg-white w-14 h-14 flex items-center justify-center"
          >
            <Icon class="w-10 h-10" i-heroicons-outline:search />
          </div>
          <h2 class="text-3xl font-bold"
            >Find a Value-Based Care Provider Near You!</h2
          >
          <Button
            class="min-w-[220px]"
            variant="blue"
            size="sx"
            to="/paap/save-money-stay-healthy"
            >Search now</Button
          >
        </div>
      </section>

      <section ref="testimonialSectionRef">
        <TestimonialCarousel
          v-if="testimonialSectionRef"
          :testimonial-section-ref="testimonialSectionRef"
        >
          <TestimonialCard
            v-for="testimonial in testimonials"
            :testimonial="testimonial"
          />
        </TestimonialCarousel>
      </section>
    </div>
  </Layout>
</template>

<style scoped>
.hero {
  background-image: url('~/assets/landing-page-pc-hero-2.png');
  background-repeat: no-repeat;
  background-position: center;
}

.grid-square {
  @apply grid;
  grid-template-columns: 1fr;

  @media screen and (min-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(4, 304px);
  }
}

.square-3 {
  @media screen and (max-width: 768px) {
    grid-column-start: span 2;
  }
}

.heart {
  grid-column-start: span 2;
  @apply justify-self-center;
}

.first-row-text {
  @media screen and (min-width: 1280px) {
    grid-column-start: span 2;
  }

  @apply font-bold items-center;
}

.second-row-text {
  grid-column-start: span 2;
  @apply self-center text-xl;
}
</style>
